<template>
    
    <div class="layout-wrapper layout-static layout-static-sidebar-inactive layout-horizontal ">
        <div class="menu-toolbar">
            <Menubar :model="modelMenu" class="border-noround">
                <template #start>
                    <router-link to="/">
                        <img alt="logo" src="@/assets/layout/images/logo-horizontal-white.png" height="40" class="p-mr-2">
                    </router-link>
                </template>
                <template #end>
                    <AppProfile />
                </template>
            </Menubar>
        </div>
        <div class="layout-main-container" ref="header">
            <div class="layout-main" >
                
                <div class="grid" data-tab="tab-test">
                    
                    <div class="col-12 md:col-12 lg:col-8 xl:col-6 xl:col-offset-3  lg:col-offset-2">
                        <div class="flex justify-content-center" >
                            <h5 class="font-bold uppercase text-center">Guía de observación del desempeño docente</h5>
                        </div>
                    </div>

                    <!--BEGIN: Home -->
                    <div class="col-12 md:col-12 lg:col-9 xl:col-8 xl:col-offset-2  lg:col-offset-2" ref="seccion" data-tab="tab-test" v-if="CURRENT_TAB == TAB.HOME">
                        <div class="flex flex-column my-2 align-items-center text-lg font-semibold text-primary">
                            <span class="my-3 text-xl">{{guiaDesempeno.facultad.fac_nombre}}</span>
                            <span class="my-1 uppercase">{{guiaDesempeno.carrera.car_nombre}}</span>
                            <span class="my-3 uppercase">{{guiaDesempeno.asignatura.plm_mat_nombre}} 
                                <i class="pi pi-book pi-fw mr-1" data-v-02652dc2=""></i> 
                                {{guiaDesempeno.plan.pla_nombre}}</span>
                            <div class="flex justify-content-end">
                                <a href="javascript:void(0)" class="label label-lg label-light-primary label-inline font-semibold ml-1 py-3 ">Grupo: {{guiaDesempeno.ddo_hor_descripcion}} </a>
                            </div>
                        </div>
                        <Divider />
                        <div class="flex flex-row mx-0 justify-content-between align-items-center flex-wrap">
                            <div class="flex flex-column mb-3 md:mb-0">
                                <div class="flex align-items-center flex-lg-fill ">
                                    <span class="mr-3">
                                        <i class="pi pi-share-alt text-4xl  text-primary"></i>
                                    </span>
                                    <a href="javascript:;" class=" text-primary font-semibold mx-2 uppercase">{{guiaDesempeno.empleado.emp_nombres_apellidos}}</a>
                                </div>
                                <div class="flex flex-column md:flex-row align-items-start">
                                    <small class="text-center text-xs">👨‍💼 {{guiaDesempeno.empleado.emp_puesto}}</small>
                                    <small class="text-center text-xs mx-2"> 💻 {{guiaDesempeno.empleado.emp_unidad}} </small>
                                </div>
                            </div>
                            <div class="flex flex-column">
                                <div class="flex align-items-center flex-lg-fill justify-content-center">
                                    <span class="mr-2">
                                        <i class="pi pi-user text-4xl  text-primary"></i>
                                    </span>
                                    <a href="javascript:;" class=" text-primary font-semibold mx-2 uppercase">{{guiaDesempeno.ddo_est_inscritos}}</a>
                                </div>
                                <small class="text-center text-xs"> Inscritos</small>
                            </div>  
                            <div class="flex flex-column">
                                <div class="flex align-items-center flex-lg-fill justify-content-center">
                                    <span class="mr-2">
                                        <i class="pi pi-users text-4xl  text-primary"></i>
                                    </span>
                                    <a href="javascript:;" class=" text-primary font-semibold mx-2 uppercase">{{guiaDesempeno.ddo_est_presentes}}</a>
                                </div>
                                <small class="text-center text-xs"> Presentes</small>
                            </div>                           
                        </div>
                        <Divider />
                        <div class="flex justify-content-center">
                            <Button :label="isSaved ? 'Ir a resultados' : 'Iniciar guía de observación'" icon="pi pi-check" class="p-button-outlined"  @click="CURRENT_TAB = TAB.TEST" />
                        </div>
                    </div>
                    <!--BEGIN: Body Test -->
                    <div class="col-12 md:col-12 lg:col-9 xl:col-8 xl:col-offset-2  lg:col-offset-2" ref="seccion" data-tab="tab-test" v-if="CURRENT_TAB == TAB.TEST">
                        <!--Body Test : Header -->
                        <div class="flex justify-content-center py-5" >
                            <h5 class="font-bold text-gray-600 uppercase text-primary">{{currentSeccion.seccion}}</h5>
                        </div>
                        <!--Body Test : Begin Content -->
                        <div class="mb-6" v-for="criterio,idxCri in currentSeccion.criterioList" :key="criterio" :ref="'crit' + idxCri">
                            <div class="flex" >
                                <h5 class="font-semibold text-lg">
                                    <span class="mr-2 text-danger">{{criterio.correlativo}}. </span>
                                    <span class="text-gray-700">{{criterio.rce_enunciado}}</span>
                                </h5>
                            </div>
                            <div class="flex  flex-column my-2 md:mx-5" >
                                <div class="flex flex-column" v-for="escala,idx in criterio.escalaList" :key="escala">
                                    <!-- Select Option -->
                                    <div v-if="!escala.hidden" class="option  border-round  my-2 p-3  flex justify-content-center text-lg text-primary font-bold"
                                        @click="selectedEscala_onClick({ criterio: criterio, index: idxCri} ,escala)"   
                                        :class="escala.selected ? 'hover:bg-blue-300 hover:text-primary bg-blue-500 text-white ' : 'bg-gray-200 hover:bg-gray-300'">
                                        {{vowel(idx)}} {{escala.ree_descripcion}}
                                    </div>
                                    <!--Restart Button -->
                                    <div class="flex justify-content-end" v-if="escala.selected && !isSaved">
                                        <a href="javascript:;" class="hover:text-primary" @click="restartEscala_onClick(criterio)"> <i class="pi pi-refresh" /> Borrar selección</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <h5 class="mt-3 font-bold uppercase text-center">- COMENTARIOS -</h5>
                        <Divider />
                        <div class="flex flex-column">
                            <div class="p-fluid">
                                <div class="field">
                                    <label for="rev_logros " class="font-bold text-primary">LOGROS *</label>
                                    <Textarea  ref="rev_logros" id="rev_logros" v-model.trim="comentario_logros" rows="5" :readonly="isSaved"
                                    :autoResize="true"  placeholder="Describa de forma sintética, los logros identificados (aspectos positivos, fortalezas, etc)" />
                                    <div class="flex flex-row" :class="{'text-danger': (comentario_logros > 2500)}">
                                        <small class="p-error" v-if="submittedSeccion && !comentario_logros.trim()">* Comentario requerido.</small>
                                        <small class="flex-grow-1 flex flex-column flex-shrink-1 align-items-end" :class="{'text-danger':comentario_logros.length < 50 }">{{comentario_logros.length}}/2500</small>
                                    </div>
                                </div>
                            </div>

                            <div class="p-fluid">
                                <div class="field">
                                    <label for="rev_logros " class="font-bold text-primary">ASPECTOS A MEJORAR *</label>
                                    <Textarea  ref="rev_logros" id="rev_logros" v-model.trim="comentario_mejoras" rows="5" :readonly="isSaved"
                                    :autoResize="true"  placeholder="De manera resumida, relacione qué aspectos se deben mejorar (de cada indicador)" />
                                    <div class="flex flex-row" :class="{'text-danger': (comentario_mejoras > 2500)}">
                                        <small class="p-error " v-if="submittedSeccion && !comentario_mejoras.trim()">* Comentario requerido.</small>
                                        <small  class="flex-grow-1 flex flex-column flex-shrink-1 align-items-end" :class="{'text-danger':comentario_mejoras.length < 50 }" >{{comentario_mejoras.length}}/2500</small>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <!--Body Test : End Content -->

                        <!--Footer Test: Buttons -->
                        <div class="flex justify-content-between py-3 " >
                            <Button :label="indexSeccion == 0 ? 'Inicio' : 'Anterior'" icon="pi pi-arrow-left" class="p-button-outlined"  @click="previousSeccion_onClick()"/>
                            <Button :label="indexSeccion + 1 < this.revSeccionEsquemaList.length ? 'Siguiente' : 'Finalizar'" icon="pi pi-arrow-right" class="p-button-outlined"  @click="nextSeccion_onClick()" />
                            <!-- <Button :label="isSaved ? 'Terminado' : 'Finalizar'" class="p-button-outlined" @click="submitDesempeno_onClick()" v-if="indexSeccion + 1 == this.revSeccionEsquemaList.length"/> -->
                        </div>

                        
                    </div>
                    <!--END: Body Test -->

                    <!--Begin: Result -->
                    <div class="col-12 md:col-12 lg:col-9 xl:col-8 xl:col-offset-2  lg:col-offset-2" data-tab="tab-result" v-if="CURRENT_TAB == TAB.RESULT"> 
                        <div class="flex flex-column align-items-center">
                            <SuccessAnimationIco />
                            <h5 class="font-semibold text-lg text-primary py-0 my-0 text-center ">Se ha finalizado la guía de observación de la visita No. {{guiaDesempeno.ddo_num_visita}}</h5>
                            <span class="font-semibold  text-lg text-gray-900 my-2 text-center">Puede visualizar en las visitas generadas de la asignatura en el listado de guía de desempeño </span>
                            <Button label="Ir a inicio de la guía de observación" icon="pi pi-home" class="p-button-outlined my-5"  @click="goHome_onClick()" />
                        </div>
                    </div>
                </div>
                
            </div>
            <AppFooter /> 
        </div>
    </div>
</template>

<script>

import {SuccessAnimation} from "@/components/svg/_svgHelper.js"
import AppFooter from '@/layout/AppFooter.vue';
import AppProfile from "@/layout/AppProfile"

import GuiaDocenteService from '@/service/guiaDocenteService'
import RevisionEsquemaService from "@/service/revisionEsquemaService"
import PlanDidacticoService from '@/service/planDidacticoService'

import methods from "@/utilities/generalMethods.js"


const TAB = {
    HOME : 1,
    TEST : 2,
    RESULT : 3
}
//SET INSTRUMENTO: Guia de observacion del desempeño docente
const INSTRUMENTO =  "INS_GODD"


export default {
    revisionEsquemaService : null,
    guiaDocenteService : null,
    planDidacticoService : null,
    data(){
        return {
            Codigo: 0, // visita
            guiaDesempeno : {
                facultad: {},
                carrera :{},
                asignatura: {},
                plan: {},
                empleado: {}
            },
            revSeccionEsquemaList: [],

            desempenoDocenteObsList: [],
            desempenoDocenteComentarioList: [],
            desempenoDocenteRevisionList : [],

            comentario_logros : "",
            comentario_mejoras : "",
            
            indexSeccion : -1,
            submittedSeccion : false,
            modelMenu : [],
            TAB :  TAB,
            CURRENT_TAB : TAB.HOME 
        }
    },
    created(){
        this.revisionEsquemaService = new RevisionEsquemaService(this.axios);
        this.guiaDocenteService = new GuiaDocenteService(this.axios);
        this.planDidacticoService =  new PlanDidacticoService(this.axios)

        this.Codigo =  this.$route.params.id
    },  
    
    async mounted(){
        try{
            this.Codigo =  this.$route.params.id
           
        
            await this.fx_getRevision();
            
            //Load schema inst 3 => Guia de observacion docente
            this.revSeccionEsquemaList   = await this.revisionEsquemaService.getEsquema("VER1_REV2022-3")
            this.indexSeccion =  0

            //Load info => Desemepeño docente
            this.guiaDesempeno = await this.guiaDocenteService.getDesempenoDocenteById(this.Codigo);

            //  await this.planDidacticoService.getPlan(codigo,0).then(res => {
            //     if(res == ""){
            //         throw "No se encontrarón resultados o no está autorizado"
            //     }
            //     this.selectedPlanDidactico = res
            // })
        }catch(ex){
            var msg = methods.messageCode(ex)
            this.$toast.add({severity:'error', summary:'¡Error!', detail:msg, life: 5000});
        }
    
    },


    methods:{
        async fx_goToPosition(index){
             //go to next position
            var element = this.$refs['crit'+index];
            var minus = index == 0 ? 150 : 0
            var top = element.offsetTop;
            console.log(element.offsetTop)
            window.scrollTo(0, top - minus);
        },
        async fx_getRevision(){
            //Load data for previous result if exists
            await this.guiaDocenteService.getRevisionByDesempeno(this.Codigo, INSTRUMENTO).then(res=>{
                this.desempenoDocenteRevisionList  =  res.revision
                this.desempenoDocenteComentarioList =  res.comentario
            }) 
        },
        goHome_onClick(){
            this.CURRENT_TAB  = TAB.HOME
            this.indexSeccion = 0
        },
        //go to next
        async nextSeccion_onClick(){
            //begin: Validations
            this.submittedSeccion =  true
            var totalSelected  = this.revSeccionEsquemaList[this.indexSeccion].criterioList.filter(x => x.escalaList.some(y=> y.selected)).length

            var totalCriterioSeccion =  this.revSeccionEsquemaList[this.indexSeccion].criterioList.length;
            if(totalSelected < totalCriterioSeccion){
                this.$toast.add({severity:'warn', summary:'¡Datos no válidos!', detail:"Completar la sección antes de continuar.", life: 5000});
                var indexCriterio =  this.currentSeccion.criterioList.findIndex(x=> x.escalaList.some(y=> !y.selected))
                this.fx_goToPosition(indexCriterio);
                return;
            }else if (!this.comentario_logros || !this.comentario_logros.trim() || !this.comentario_mejoras || !this.comentario_mejoras.trim() )
            {
                this.$toast.add({severity:'warn', summary:'¡Datos no válidos!', detail:"Completar los comentarios de la sección.", life: 5000});
                return;
            }
            //end: Validations

            //Verify if exists comment on list
            var indexExists  =  this.desempenoDocenteComentarioList.some(x=> x.ddc_codrse == this.revSeccionEsquemaList[this.indexSeccion].codseccion);
            if(!indexExists){
                //add new coments 
                this.desempenoDocenteComentarioList.push({
                    ddc_codigo : 0,
                    ddc_codddo : this.Codigo,
                    ddc_codrse : this.revSeccionEsquemaList[this.indexSeccion].codseccion,
                    ddc_logro : this.comentario_logros,
                    ddc_mejora : this.comentario_mejoras,
                    ddc_codusr_creador : this.$store.getters.getUserId
                })
            }

            //go to next seccion
            var totalSeccion =  this.revSeccionEsquemaList.length;
            if(this.indexSeccion < totalSeccion - 1){
                this.indexSeccion++;
                // var element = this.$refs['header'];
                // var top = element.offsetTop;
                // window.scrollTo(0,top);
            }else{
               await this.submitDesempeno_onClick()
            }


            // //restart values
            this.submittedSeccion =  false
            this.comentario_logros =  ""
            this.comentario_mejoras =  ""

          
        },
        
        //go to previous
        previousSeccion_onClick(){
            if(this.indexSeccion != 0){
                this.indexSeccion--;
            }else{
                this.CURRENT_TAB =  TAB.HOME
            }      
        },
        async selectedEscala_onClick(refValue,eEscala){
            
            if(this.isSaved) {
                //can´t edit
                return;
            } 
            //go to next position
            this.fx_goToPosition(refValue.index)
            
            eEscala.selected = true;
            //set other value to <false>
            refValue.criterio.escalaList.forEach(esc => {
                esc.hidden = esc.selected != true
            });
        },

        restartEscala_onClick(criterio){

            //if exists
            if(this.isSaved){
                return;
            }
            //unselect & unhidden items
            criterio.escalaList.forEach((element)=>{
                element.selected =  false
                element.hidden =  false
            })
           
        },

        async  submitDesempeno_onClick(){
            try{

                setTimeout(function () {
                    window.scrollTo(0, 0);
                },2);

                if(this.isSaved){
                    this.CURRENT_TAB = TAB.RESULT
                    this.$toast.add({severity:'warn', summary:'¡No guardado!', detail:"Ya se ha realizado con anterioridad", life: 5000});
                    return;
                }
                //Prepare data
                var dataToSend = []
                
                this.revSeccionEsquemaList.forEach((sec)=>{
                    var data = {}
                    sec.criterioList.forEach((cri)=>{
                        var found =   cri.escalaList.find(x=> x.selected);
                        data = {
                            ddr_codigo : 0,
                            ddr_codddo : this.Codigo,
                            ddr_codrve : found.rve_codigo,
                            ddr_inst : INSTRUMENTO,
                            ddr_codusr_creador : this.$store.getters.getUserId
                        }
                        dataToSend.push(data)
                    })
                });
                await this.guiaDocenteService.createRevisionDesempeno(
                {
                    revision: dataToSend,
                    comentario: this.desempenoDocenteComentarioList
                });
                this.$toast.add({severity:'success', summary:'¡Éxito!', detail:"Se ha realizado la revisión con éxito", life: 5000});

                this.CURRENT_TAB = TAB.RESULT
                await this.fx_getRevision();
            }catch(ex){
                var msg = methods.messageCode(ex)

                this.$toast.add({severity:'error', summary:'¡Error!', detail:msg, life: 5000});
            }
        },
        //utilities
        /**
         * Convertir un index a vocal
         */
        vowel(idx){
            return methods.integerToVowel(idx);
        }
    },

    watch:{
        indexSeccion:function(newVal){
            //****** Get preview value ******
            //restar Value
            this.comentario_logros = ""
            this.comentario_mejoras = ""
            //Get comments values
            var indexExists  =  this.desempenoDocenteComentarioList.findIndex(x=> x.ddc_codrse == this.revSeccionEsquemaList[newVal].codseccion);
            if(indexExists >= 0){
                this.comentario_logros = this.desempenoDocenteComentarioList[indexExists].ddc_logro
                this.comentario_mejoras = this.desempenoDocenteComentarioList[indexExists].ddc_mejora
            }

            setTimeout(function () {
                    window.scrollTo(0, 0);
            },2);
        }
    },
    computed:{
        currentSeccion(){
            if(this.revSeccionEsquemaList.length == 0 || this.indexSeccion == -1) 
                return []
            

            var current =  this.revSeccionEsquemaList[this.indexSeccion]

            var i  = 0; // correaltivo
            //this.revSeccionEsquemaList.forEach((item)=>{
                current.criterioList.forEach((cri)=>{
                    i++;
                    //add property for correlativo
                    if(!("correlativo" in cri))
                        cri["correlativo"] = i;
                    
                    var selectedIndex = -1
                    cri.escalaList.forEach((esc)=>{
                        //add property for selected escala/criterio
                        if(!("selected" in esc)){
                            esc["selected"] =  false
                            esc["hidden"] = false
                        }
                        
                        //Change value for selected in DataBase
                        if(this.desempenoDocenteRevisionList.length > 0){
                            selectedIndex =  this.desempenoDocenteRevisionList.findIndex(x=> x.ddr_codrve == esc.rve_codigo)
                            esc.selected =  selectedIndex != -1
                        }
                    })

                    //hidden the other data if is selected
                    var isSelectedData =  cri.escalaList.some(x=> x.selected);
                    if(isSelectedData){
                        cri.escalaList.forEach((esc) => {
                            esc.hidden =  esc.selected != true
                        })
                    }
                    
                })
            //})
            return  current
        },
        
        isSaved(){
            //if items were saved
            var total = this.desempenoDocenteRevisionList.length ;
            return total > 0
        }
    },
    components:{
        AppFooter,
        AppProfile,
        SuccessAnimationIco :  SuccessAnimation
    }
}
</script>

<style lang="scss" scoped>
    .option {
        transition: 0.3s;
        cursor: pointer;
        border-radius: 25px !important;
        &:active {
            transform: scaleX(0.9);
        }
    }
</style>
<style>
    html {
        scroll-behavior: smooth;
        height: 100%; overflow:auto;
    }
    body { height: 100%; }

    
</style>
